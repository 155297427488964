import mermaid from 'https://unpkg.com/mermaid@9/dist/mermaid.esm.min.mjs';
import {marked} from 'https://cdn.omega365.com/libs/marked/v4.0.2/marked.esm.min.js';

//import sheet from 'https://cdn.omega365.com/libs/marked/github.markdown.min.css' assert { type: 'css' };
//document.adoptedStyleSheets = [sheet];
//shadowRoot.adoptedStyleSheets = [sheet];

const o365_marked = {
    name: "marked",
    namespace: "directives",

    mounted(el, binding, vnode, prevVnode) {
        // let renderer = new marked.Renderer();
        // renderer.code = function (code, language) {
        //     if (code.match(/^sequenceDiagram/) || code.match(/^graph/)) {
        //         return '<pre class="mermaid">' + code + '</pre>';
        //     } else {
        //         return `<pre class="language-${language} line-numbers"><code>` + code + '</code></pre>';
        //     }
        // };
        // marked.use({ renderer });

        el.innerHTML = marked.parse(binding.value ?? '');
        el.classList.add('line-numbers');

        Prism.highlightAllUnder(el);

        mermaid.init(undefined, '.language-mermaid');
    },

    updated(el, binding, vnode) {
        // let renderer = new marked.Renderer();
        // renderer.code = function (code, language) {
        //     if (code.match(/^sequenceDiagram/) || code.match(/^graph/)) {
        //         return '<pre class="mermaid">' + code + '</pre>';
        //     } else {
        //         return `<pre class="language-${language} line-numbers"><code>` + code + '</code></pre>';
        //     }
        // };
        // marked.use({ renderer });        
        
        el.innerHTML = marked.parse(binding.value ?? '');

        Prism.highlightAllUnder(el);

        mermaid.init(undefined, '.language-mermaid');
    },
}


export default o365_marked 
